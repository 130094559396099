<template>
  <div v-loading="loading" class="edit-form-wrapper">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      label-width="120px"
      class="edit-form"
    >
      <el-form-item
        :label="$t('pages.devices.edit.form.properties.name.label')"
        prop="name"
      >
        <el-input
          v-model="form.name"
          data-lpignore="true"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
        />
      </el-form-item>
      <div>
        <h3>{{ $t('pages.devices.edit.form.properties.content.header') }}</h3>
        <contents v-model="form.contents" />
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import Contents from './contents'

function genInitialData() {
  return {
    name: null,
    contents: {}
  }
}

function makeHandleableBody(payload, form) {
  return pick(payload, Object.keys(form))
}

export default {
  components: {
    Contents
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      valid: false,
      attributes: [],
      form: genInitialData(),
      payload: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.devices.edit.form.validation.name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('pages.devices.edit.form.validation.name.length'),
            trigger: 'blur'
          }
        ],
        'template_config.runtime_url': [
          {
            required: true,
            message: this.$t(
              'pages.devices.edit.form.validation.runtime_url.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    allowedTypes() {
      return [
        {
          value: 'cfd',
          label: this.$t('pages.devices.edit.form.properties.type.cfd.label')
        },
        {
          value: 'printer',
          label: this.$t(
            'pages.devices.edit.form.properties.type.printer.label'
          )
        }
      ]
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetData()
      if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.$route.params.id)
    }
  },
  mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        const inst = th.contentTemplates()

        this.loading = true
        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false

        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.device.singular')
          })
        })
      }
    },
    handleItem(item) {
      this.payload = { ...item }

      if (!item.device_configuration) item.device_configuration = {}
      if (!item.contents) item.contents = {}

      this.form = {
        ...pick(item, Object.keys(this.form))
      }
    },
    submitForm(formName) {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.message.invalid_inputs')
          })
        }

        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetData(formName) {
      this.form = genInitialData()
    },
    async alter(id) {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.device.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.device.singular')
      })

      const payload = {
        ...this.payload,
        ...this.form
      }

      try {
        const inst = th.contentTemplates()
        this.loading = true

        const { data = {} } = await inst.patch(
          payload.id,
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.$message({
            type: 'success',
            message: successMessage
          })

          this.handleItem(data)
        }

        this.loading = false
        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async create() {
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.device.singular')
      })
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.device.singular')
      })

      const payload = {
        ...this.form
      }

      try {
        const inst = th.contentTemplates()
        this.loading = true

        const { data = {} } = await inst.create(
          makeHandleableBody(payload, this.form)
        )

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: successMessage
          })

          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'utilities-cms-content-templates-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'utilities-cms-content-templates-all' })
          }
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.delete()
    },
    async delete() {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.device.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.device.singular')
      })

      try {
        const inst = th.contentTemplates()
        await inst.delete(this.payload.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.$router.push({ name: 'utilities-cms-content-templates-all' })
      } catch (err) {
        this.$logException(err, { message: errorMessage })
      }
    }
  }
}
</script>

<style scoped>
.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  overflow: hidden;
  padding: 20px;
  width: 500px;
}

.form-column {
  margin: 0;
  padding: 1em;
}

.input-warning {
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.input-warning :deep(.el-icon-warning) {
  color: #ec4d3c;
  padding-right: 2px;
}

.input-warning :deep(.warning-text) {
  color: #4a4a4a;
}

.image-container {
  min-width: 140px;
  max-width: 180px;
  height: 130px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  overflow: auto;
  margin-bottom: 22px;
}

.image {
  width: 100%;
  height: 100%;
}

.el-form,
.el-form > *,
.el-form > * > * {
  height: 100%;
}

.content-column {
  overflow-x: hidden;
}
</style>
