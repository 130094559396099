<template>
  <div v-loading="loading">
    <div v-for="location in avaialableContentLocations" :key="location">
      <h4 v-text="translation[location]" />
      <div
        v-for="(item, index) in modelValue[location] || []"
        :key="index"
        class="content-item"
      >
        <contents-row
          :key="loading"
          :model-value="{
            ...(contents.find((contentItem) => contentItem.id === item) || {
              id: item
            })
          }"
          :options="contents"
          @update:modelValue="(v) => handleFormInput(v, location, index)"
        />
      </div>

      <remote-search-select
        :computed-fields="['name']"
        :resource-query="{ query: { deleted: false } }"
        :model-value="null"
        popper-append-to-body
        resource="contents"
        class="w-64"
        @update:modelValue="(v) => handleFormInput(v, location)"
      />
    </div>
  </div>
</template>

<script>
import ContentsRow from './contents-row'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    ContentsRow,
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      contents: [],
      avaialableContentLocations: [
        'idle',
        'welcome',
        'cart',
        'payment',
        'payment_terminal',
        'payment_approved',
        'goodbye',
        'logo'
      ]
    }
  },
  computed: {
    translation() {
      return {
        cart: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.cart.label'
        ),
        goodbye: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.goodbye.label'
        ),
        idle: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.idle.label'
        ),
        logo: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.logo.label'
        ),
        payment: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.payment.label'
        ),
        payment_approved: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.payment_approved.label'
        ),
        payment_terminal: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.payment_terminal.label'
        ),
        welcome: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.welcome.label'
        )
      }
    }
  },
  async created() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      try {
        this.loading = true
        const { contents } = await this.$resourceFetch('contents')
        this.contents = contents
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: err.message
        })
      }
    },
    querySearch(queryString, cb) {
      const results = queryString
        ? this.contents
            .map((item) => ({ value: item.id }))
            .filter(this.createFilter(queryString))
        : this.contents
      // call callback function to return suggestions
      cb(results)
    },
    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleFormInput(v, path, index) {
      if (Number.isFinite(index)) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          [path]: this.modelValue[path]
            .map((contentItem, contentIndex) => {
              if (contentIndex === index) return v
              return contentItem
            })
            .filter((item) => item)
        })
        return
      }

      this.$emit('update:modelValue', {
        ...this.modelValue,
        [path]: [...(this.modelValue[path] || []), v].filter((item) => item)
      })
    }
  }
}
</script>

<style lang="css" scoped>
.content-item {
  height: 3rem;
  overflow: hidden;
}
</style>
