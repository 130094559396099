<template>
  <div v-if="!!modelValue.id" class="content-row">
    <div v-if="modelValue.type" class="content-type">
      <el-icon v-if="modelValue.type === 'video'"><VideoPlay /></el-icon>
      <el-icon v-if="modelValue.type === 'image'"><Picture /></el-icon>
    </div>

    <remote-search-select
      :computed-fields="['name']"
      :resource-query="{ query: { deleted: false } }"
      :model-value="modelValue.id"
      resource="contents"
      popper-append-to-body
      class="w-64"
      @update:modelValue="(v) => $emit('update:modelValue', v)"
    />

    <div v-if="modelValue.payload" class="content-preview">
      <img v-if="modelValue.type === 'image'" :src="modelValue.payload" />
      <video
        v-if="modelValue.type === 'video'"
        autoplay
        muted
        :src="modelValue.payload"
      />
    </div>

    <div class="content-tools">
      <router-link
        :to="{
          name: 'utilities-cms-contents-edit',
          params: { id: modelValue.id }
        }"
      >
        <el-button icon="View" class="el-button--text-icon" plain />
      </router-link>
    </div>
  </div>
</template>

<script>
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined,
      required: false
    }
  }
}
</script>

<style lang="css" scoped>
.content-row {
  display: flex;
  align-content: center;
  align-items: center;
  height: 100%;
  overflow-y: hidden;
}

.content-row > * {
  margin-right: 0.5rem;
}

.content-preview {
  padding: 0.2rem;
  height: 100%;
  width: auto;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  width: 5rem;
  overflow: hidden;
}

.content-preview > * {
  width: 100%;
  height: 100%;
}

i {
  font-size: 2rem;
  color: #e4e4e4;
}

.content-tools .el-button {
  border: 0;
}
</style>
